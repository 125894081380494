:root {
  --fc-button-bg-color: #ff6448;
  --fc-button-border-color: #ff6448;
  --fc-button-hover-bg-color: #ff836d;
  --fc-button-hover-border-color: #ff836d;
  --fc-button-active-bg-color: #cc503a;
  --fc-button-active-border-color: #cc503a;
}

.fc {
  @apply h-full;

  .fc-day-today {
    background-color: var(--fc-today-bg-color);
  }
}

.fc-header-toolbar {
  @apply absolute -top-[52px] z-10 !items-stretch gap-3 text-sm lg:-left-[282px];

  & > * {
    @apply h-9;
  }

  .fc-toolbar-chunk {
    &:first-child {
      button {
        @apply flex h-9 items-center !border-soft-200 bg-white-0 px-4 py-2 font-medium capitalize !text-sub-600;

        &:active {
          @apply bg-weak-100;
        }

        &:first-child {
          @apply rounded-l-8;
        }

        &:last-child {
          @apply rounded-r-8;
        }

        &.fc-button-active {
          @apply bg-weak-50 !text-strong-950;
        }
      }
    }

    &:nth-child(2) {
      @apply shrink-0 rounded-8 border border-soft-200 px-2;

      & > div {
        @apply flex h-full items-center gap-2;
      }

      button {
        @apply size-6 border-none !bg-transparent bg-center;

        & > span {
          @apply hidden;
        }

        &.fc-prev-button {
          background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M9.20694 9.99932L12.9194 13.7118L11.8589 14.7723L7.08594 9.99932L11.8589 5.22632L12.9194 6.28682L9.20694 9.99932Z" fill="rgba(82,88,102,1)"/></svg>') !important;
          @media (prefers-color-scheme: dark) {
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M9.20694 9.99932L12.9194 13.7118L11.8589 14.7723L7.08594 9.99932L11.8589 5.22632L12.9194 6.28682L9.20694 9.99932Z" fill="rgba(153,160,174,1)"/></svg>') !important;
          }
        }
        &.fc-next-button {
          background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M10.7984 9.99932L7.08594 6.28682L8.14644 5.22632L12.9194 9.99932L8.14644 14.7723L7.08594 13.7118L10.7984 9.99932Z" fill="rgba(82,88,102,1)"/></svg>') !important;
          @media (prefers-color-scheme: dark) {
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M10.7984 9.99932L7.08594 6.28682L8.14644 5.22632L12.9194 9.99932L8.14644 14.7723L7.08594 13.7118L10.7984 9.99932Z" fill="rgba(153,160,174,1)"/></svg>') !important;
          }
        }
      }

      .fc-toolbar-title {
        @apply text-sm font-medium text-sub-600;
      }
    }

    &:nth-child(3) {
      @apply hidden;
    }
  }
}

table.fc-scrollgrid {
  @apply !overflow-hidden !rounded-xl !border !border-soft-200;

  & > thead > tr {
    th {
      @apply border-soft-200;

      /* header that contains resource selector */
      &:first-child {
        table.fc-datagrid-header {
          th.fc-datagrid-cell {
            @apply h-8 border-soft-200;

            .fc-datagrid-cell-cushion {
              @apply bg-white-0;
            }
          }
        }
      }

      /* header that contains the days */
      &:last-child {
        @apply border-r-0;

        table {
          th {
            @apply bg-weak-50 font-medium text-soft-400;

            & > div {
              @apply !h-8;

              a {
                @apply !text-xs;
              }
            }
          }
        }
      }
    }

    /* header part of divider */
    td.fc-resource-timeline-divider {
      @apply border-b border-soft-200 !border-r-transparent !bg-weak-50;
    }
  }

  /* single row containing the whole body and sub tables */
  & > tbody > tr {
    td {
      @apply border-b-0 border-soft-200;

      table.fc-datagrid-body {
        .fc-datagrid-cell-frame {
          @apply flex items-center;

          .fc-datagrid-cell-cushion {
            @apply bg-white-0 text-sm text-strong-950;
          }
        }
      }

      &:last-child {
        @apply border-r-0;

        .fc-day {
          @apply text-sm font-medium text-soft-400;

          &.fc-day-other {
            @apply text-soft-400;
          }

          .fc-daygrid-day-top {
            @apply mt-2 justify-center;
          }
        }

        /* hide the scrollbar track if there's no scrollbar */
        .fc-scroller {
          @apply !overflow-auto;
        }
      }
    }

    /* body part of divider */
    td.fc-resource-timeline-divider {
      @apply !border-transparent !bg-white-0;
    }
  }
}

/* full calendar list view */
.fc-listMonth-view {
  @apply !overflow-hidden !border-0;

  /* header containing the date and week day */
  & table tbody tr.fc-day th {
    @apply border-transparent !bg-white-0;
    div {
      @apply rounded-8 bg-weak-50 text-sm font-medium text-sub-600;
      .fc-list-day-text {
        @apply uppercase;
      }
    }
  }

  & table tbody tr.fc-event {
    &:hover td {
      @apply bg-transparent;
    }
    td {
      @apply border-transparent;
      &.fc-list-event-time {
        @apply hidden;
      }
      &.fc-list-event-graphic {
        @apply hidden;
      }
    }
  }
}

.fc-event {
  @apply !m-2 !rounded-8 border-none bg-transparent p-0;

  &:hover {
    @apply bg-transparent;
  }
}

.fc-timeline-header,
.fc-col-header-cell {
  text-transform: uppercase;
  background-color: #fbfcfe;
}

.fc-datagrid-cell-frame {
  text-align: center !important;
}

.fc-datagrid-cell-cushion {
  width: 100% !important;
  background-color: #fbfcfe;
}

.fc-timeline-slot-cushion,
.fc-col-header-cell-cushion {
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
}

.fc-button-primary:focus,
.fc-button:focus {
  box-shadow: none !important;
}

.fc-datagrid-cell-main {
  text-wrap: wrap;
}

/* I don't like this as it will potentially cause issues should the input height be different / etc
but necessary for now because selectBounds resolves to 0 that makes width 0 with the existing dropdown library */
/* Scoping to scheduling-slideover class name to avoid affecting other dropdowns */
.scheduling-slideover .react-dropdown-select-dropdown {
  width: 100% !important;
  top: 50px !important;
}
