.dialogSurface[data-state='closed'] {
  animation: dialogCloseKeyframes 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.dialogSurface[data-state='open'] {
  animation: dialogOpenKeyframes 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

@keyframes dialogOpenKeyframes {
  from {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x))
      skewY(var(--tw-skew-y)) scaleX(0.75) scaleY(0.75);
    opacity: 0;
  }

  to {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x))
      skewY(var(--tw-skew-y)) scaleX(1) scaleY(1);
    opacity: 1;
  }
}

@keyframes dialogCloseKeyframes {
  from {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x))
      skewY(var(--tw-skew-y)) scaleX(1) scaleY(1);
    opacity: 1;
  }

  to {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x))
      skewY(var(--tw-skew-y)) scaleX(0.75) scaleY(0.75);
    opacity: 0;
  }
}
