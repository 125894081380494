/** @todo merge css with ag-grid.css **/
/** ag grid **/
.ag-row {
  z-index: 0;
}

.ag-row.ag-row-hover {
  z-index: 1;
}

.ag-center-cols-viewport {
  min-height: 410px !important;
}

.hide-filter-indicator .ag-header-cell-label .ag-icon-filter {
  display: none;
}

.ag-header .ag-header-cell {
  text-transform: capitalize;
}

.ag-header-cell-label .ag-sort-order {
  display: none;
}

.ag-row-group-indent-1 {
  .ag-group-checkbox {
    display: none !important;
  }
}

.ag-row-group-indent-2 {
  .ag-group-checkbox {
    display: none !important;
  }
}

.ag-row-group-indent-3 {
  .ag-group-checkbox {
    display: none !important;
  }
}

.ag-row-group-indent-4 {
  .ag-group-checkbox {
    display: none !important;
  }
}
