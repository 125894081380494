.rdp-months {
  @apply relative flex flex-row;
}

.rdp-nav .rdp-button_previous {
  @apply absolute left-[26px] top-[26px];
}

.rdp-nav .rdp-button_next {
  @apply absolute right-[26px] top-[26px];
}

.rdp-nav > button {
  @apply flex items-center justify-center rounded-6 bg-background text-sub-600;
}

.rdp-month_caption {
  @apply flex h-9 items-center justify-center rounded-8 bg-weak-50 p-2 text-sm text-sub-600;
}

.rdp-month {
  @apply flex flex-col gap-2 p-5;
}

.rdp-month:not(:first-of-type) {
  @apply border-l;
}

.rdp-weekday {
  @apply h-12 text-sm font-medium text-soft-400;
}

.rdp-day {
  @apply relative p-1 text-sub-600;
}

.rdp-day.rdp-outside,
.rdp-day.rdp-disabled {
  @apply text-disabled-300;
}

.rdp-day_button {
  @apply flex h-10 w-10 items-center justify-center rounded-8 text-sm font-medium;
}

.rdp-selected .rdp-day_button,
.rdp-selected .rdp-day_button {
  @apply bg-primary-base text-static-white;
}

.rdp-range_middle.rdp-selected .rdp-day_button {
  @apply bg-transparent text-primary-base;
}

.rdp-range_start::after {
  content: ' ';
  @apply pointer-events-none absolute bottom-1 right-0 top-1 w-4 bg-primary-base/10;
}

.rdp-range_end::after {
  content: ' ';
  @apply pointer-events-none absolute bottom-1 left-0 top-1 w-4 bg-primary-base/10;
}

.rdp-range_start.rdp-range_end::after {
  visibility: hidden;
}

.rdp-range_middle::after {
  content: ' ';
  @apply pointer-events-none absolute bottom-1 left-0 right-0 top-1 bg-primary-base/10;
}

.rdp-day:first-of-type.rdp-range_middle::after {
  @apply left-1 rounded-l-8;
}

.rdp-day:last-of-type.rdp-range_middle::after {
  @apply right-1 rounded-r-8;
}

.size-small .rdp-month {
  @apply gap-1 p-3;
}

.size-small .rdp-month_caption {
  @apply h-8;
}

.size-small .rdp-nav .rdp-button_previous {
  @apply left-[16px] top-[16px];
}

.size-small .rdp-nav .rdp-button_next {
  @apply right-[16px] top-[16px];
}

.size-small .rdp-weekday {
  @apply h-9;
}

.size-small .rdp-day {
  @apply p-0.5;
}

.size-small .rdp-day_button {
  @apply h-8 w-8 text-xs;
}
