.sendbird-theme--light {
  .sendbird-hammr {
    --sendbird-light-background-100: rgb(var(--raw-weak-50));
    --sendbird-light-background-200: rgb(var(--raw-weak-100));
    --sendbird-light-onlight-01: rgb(var(--raw-foreground));
    --sendbird-light-ondark-01: rgb(var(--raw-background));
    --sendbird-light-background-50: rgb(var(--raw-background));
    --sendbird-light-primary-300: rgb(var(--raw-primary-base) / 0.24);
    --sendbird-light-primary-400: rgb(var(--raw-primary-base) / 0.4);

    .sendbird-conversation {
      border: none;
    }

    .sendbird-text-message-item-body {
      @apply rounded-10 text-strong-950;

      &.outgoing,
      &:hover.outgoing {
        @apply bg-primary-base/25;
      }

      &.incoming,
      &:hover.incoming {
        @apply border border-soft-200 bg-weak-50;
      }
    }

    .sendbird-channel-list {
      @apply border-r border-soft-200;
    }

    .sendbird-place-holder__body {
      color: rgb(var(--raw-sub-600));

      .sendbird-place-holder__body__icon svg [class*='fill'] {
        fill: currentColor;
      }

      .sendbird-label--color-onbackground-2 {
        color: currentColor;
      }
    }
  }
}
