@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .visible-scrollbar::-webkit-scrollbar {
    width: 12px;
  }

  .visible-scrollbar::-webkit-scrollbar-track {
    border-radius: 12px;
    background: #f9fafb;
  }

  .visible-scrollbar::-webkit-scrollbar-thumb {
    @apply bg-gray-600;
    border-radius: 12px;
    border: 3px solid #f9fafb;
  }

  .visible-scrollbar::-webkit-scrollbar-thumb:hover {
    border: 2.5px solid #f9fafb;
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Change the background of the scrollbar */
::-webkit-scrollbar-track {
  @apply bg-transparent;
}

/* Change the color of the scrollbar itself */
::-webkit-scrollbar-thumb {
  @apply rounded-6 bg-soft-200;
}

/* Change the color on hover */
::-webkit-scrollbar-thumb:hover {
  @apply bg-soft-200;
}

@layer base {
  :root {
    /* Shadcn colors */
    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;
    --primary: 0 0% 9%;
    --primary-foreground: 0 0% 98%;
    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0 0% 9%;
    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;
    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;
    --ring: 0 0% 3.9%;

    /* Raw colors */
    --raw-color-orange-50: 254 245 238;
    --raw-color-orange-100: 253 233 215;
    --raw-color-orange-200: 250 207 174;
    --raw-color-orange-300: 246 173 123;
    --raw-color-orange-400: 242 128 69;
    --raw-color-orange-500: 238 96 35;
    --raw-color-orange-600: 223 69 23;
    --raw-color-orange-700: 185 51 21;
    --raw-color-orange-800: 147 42 25;
    --raw-color-orange-900: 119 38 23;
    --raw-color-orange-950: 64 16 10;
    --raw-color-orange-alpha-24: var(--raw-color-orange-500) / 24%;
    --raw-color-orange-alpha-16: var(--raw-color-orange-500) / 16%;

    --raw-color-neutral-0: 255 255 255;
    --raw-color-neutral-50: 245 247 250;
    --raw-color-neutral-100: 242 245 248;
    --raw-color-neutral-200: 225 228 234;
    --raw-color-neutral-300: 202 207 216;
    --raw-color-neutral-400: 153 160 174;
    --raw-color-neutral-500: 113 119 132;
    --raw-color-neutral-600: 82 88 102;
    --raw-color-neutral-700: 43 48 59;
    --raw-color-neutral-800: 34 37 48;
    --raw-color-neutral-900: 24 27 37;
    --raw-color-neutral-950: 14 18 27;
    --raw-color-neutral-alpha-24: var(--raw-color-neutral-400) / 24%;
    --raw-color-neutral-alpha-16: var(--raw-color-neutral-400) / 16%;

    --raw-color-red-50: 255 235 236;
    --raw-color-red-100: 255 213 216;
    --raw-color-red-200: 255 192 197;
    --raw-color-red-300: 255 151 160;
    --raw-color-red-400: 255 104 117;
    --raw-color-red-500: 251 55 72;
    --raw-color-red-600: 233 53 68;
    --raw-color-red-700: 208 37 51;
    --raw-color-red-800: 173 31 43;
    --raw-color-red-900: 139 24 34;
    --raw-color-red-950: 104 18 25;
    --raw-color-red-alpha-24: var(--raw-color-red-500) / 24%;
    --raw-color-red-alpha-16: var(--raw-color-red-500) / 16%;

    --raw-color-green-50: 224 250 236;
    --raw-color-green-100: 208 251 233;
    --raw-color-green-200: 194 245 218;
    --raw-color-green-300: 132 235 180;
    --raw-color-green-400: 62 224 137;
    --raw-color-green-500: 31 193 107;
    --raw-color-green-600: 29 175 97;
    --raw-color-green-700: 23 140 78;
    --raw-color-green-800: 26 117 68;
    --raw-color-green-900: 22 100 59;
    --raw-color-green-950: 11 70 39;
    --raw-color-green-alpha-24: var(--raw-color-green-500) / 24%;
    --raw-color-green-alpha-16: var(--raw-color-green-500) / 16%;

    --raw-color-purple-50: 239 235 255;
    --raw-color-purple-200: 202 192 255;
    --raw-color-purple-400: 140 113 246;
    --raw-color-purple-500: 125 82 244;
    --raw-color-purple-950: 53 26 117;
    --raw-color-purple-alpha-24: 120 77 239 / 24%;
    --raw-color-purple-alpha-16: 120 77 239 / 16%;

    --raw-color-blue-50: 235 241 255;
    --raw-color-blue-100: 213 226 255;
    --raw-color-blue-200: 192 213 255;
    --raw-color-blue-300: 151 186 255;
    --raw-color-blue-400: 104 149 255;
    --raw-color-blue-500: 51 92 255;
    --raw-color-blue-600: 53 89 233;
    --raw-color-blue-700: 37 71 208;
    --raw-color-blue-800: 31 59 173;
    --raw-color-blue-900: 24 47 139;
    --raw-color-blue-950: 18 35 104;
    --raw-color-blue-alpha-24: 71 108 255 / 24%;
    --raw-color-blue-alpha-16: 71 108 255 / 16%;

    --raw-color-yellow-50: 255 250 235;
    --raw-color-yellow-100: 255 239 204;
    --raw-color-yellow-200: 255 236 192;
    --raw-color-yellow-300: 255 224 151;
    --raw-color-yellow-400: 255 210 104;
    --raw-color-yellow-500: 246 181 30;
    --raw-color-yellow-600: 230 168 25;
    --raw-color-yellow-700: 201 154 44;
    --raw-color-yellow-800: 167 128 37;
    --raw-color-yellow-900: 134 102 29;
    --raw-color-yellow-950: 98 76 24;
    --raw-color-yellow-alpha-24: 251 198 75 / 24%;
    --raw-color-yellow-alpha-16: 251 198 75 / 16%;

    --raw-color-sky-50: 235 248 255;
    --raw-color-sky-100: 213 241 255;
    --raw-color-sky-200: 192 234 255;
    --raw-color-sky-300: 151 220 255;
    --raw-color-sky-400: 104 205 255;
    --raw-color-sky-500: 71 194 255;
    --raw-color-sky-600: 53 173 233;
    --raw-color-sky-700: 37 151 208;
    --raw-color-sky-800: 31 126 173;
    --raw-color-sky-900: 24 101 139;
    --raw-color-sky-950: 18 75 104;
    --raw-color-sky-alpha-24: var(--raw-color-sky-500) / 24%;
    --raw-color-sky-alpha-16: var(--raw-color-sky-500) / 16%;

    --raw-color-pink-50: 255 235 244;
    --raw-color-pink-100: 255 213 234;
    --raw-color-pink-200: 255 192 223;
    --raw-color-pink-300: 255 151 203;
    --raw-color-pink-400: 255 104 179;
    --raw-color-pink-500: 251 75 163;
    --raw-color-pink-600: 233 53 143;
    --raw-color-pink-700: 208 37 122;
    --raw-color-pink-800: 173 31 102;
    --raw-color-pink-900: 139 24 82;
    --raw-color-pink-950: 104 18 61;
    --raw-color-pink-alpha-24: var(--raw-color-pink-500) / 24%;
    --raw-color-pink-alpha-16: var(--raw-color-pink-500) / 16%;

    --raw-color-teal-50: 228 251 248;
    --raw-color-teal-100: 208 251 245;
    --raw-color-teal-200: 194 245 238;
    --raw-color-teal-300: 132 235 221;
    --raw-color-teal-400: 63 222 201;
    --raw-color-teal-500: 34 211 187;
    --raw-color-teal-600: 29 175 156;
    --raw-color-teal-700: 23 140 125;
    --raw-color-teal-800: 26 117 105;
    --raw-color-teal-900: 22 100 90;
    --raw-color-teal-950: 11 70 62;
    --raw-color-teal-alpha-24: var(--raw-color-teal-500) / 24%;
    --raw-color-teal-alpha-16: var(--raw-color-teal-500) / 16%;

    /* tokens */

    --raw-static-black: var(--raw-color-neutral-950);
    --raw-static-white: var(--raw-color-neutral-0);
    --raw-strong-950: var(--raw-color-neutral-950);
    --raw-strong-400: var(--raw-color-neutral-400);
    --raw-surface-800: var(--raw-color-neutral-800);
    --raw-sub-600: var(--raw-color-neutral-600);
    --raw-sub-300: var(--raw-color-neutral-300);
    --raw-soft-400: var(--raw-color-neutral-400);
    --raw-soft-200: var(--raw-color-neutral-200);
    --raw-weak-50: var(--raw-color-neutral-50);
    --raw-weak-100: var(--raw-color-neutral-100);
    --raw-white-0: var(--raw-color-neutral-0);
    --raw-disabled-300: var(--raw-color-neutral-300);

    --raw-primary-dark: var(--raw-color-orange-800);
    --raw-primary-darker: var(--raw-color-orange-700);
    --raw-primary-base: var(--raw-color-orange-500);

    --raw-feature-dark: var(--raw-color-purple-950);
    --raw-feature-base: var(--raw-color-purple-500);
    --raw-feature-light: var(--raw-color-purple-200);
    --raw-feature-lighter: var(--raw-color-purple-50);

    --raw-faded-dark: var(--raw-color-neutral-800);
    --raw-faded-base: var(--raw-color-neutral-500);
    --raw-faded-light: var(--raw-color-neutral-200);
    --raw-faded-lighter: var(--raw-color-neutral-100);

    --raw-information-dark: var(--raw-color-blue-950);
    --raw-information-base: var(--raw-color-blue-500);
    --raw-information-light: var(--raw-color-blue-200);
    --raw-information-lighter: var(--raw-color-blue-50);

    --raw-away-dark: var(--raw-color-yellow-950);
    --raw-away-base: var(--raw-color-yellow-500);
    --raw-away-light: var(--raw-color-yellow-200);
    --raw-away-lighter: var(--raw-color-yellow-50);

    --raw-warning-dark: var(--raw-color-orange-950);
    --raw-warning-base: var(--raw-color-orange-500);
    --raw-warning-light: var(--raw-color-orange-200);
    --raw-warning-lighter: var(--raw-color-orange-50);

    --raw-error-base: var(--raw-color-red-500);
    --raw-error-darker: var(--raw-color-red-700);
    --raw-error-light: var(--raw-color-red-200);
    --raw-error-lighter: var(--raw-color-red-50);

    --raw-success-dark: var(--raw-color-green-950);
    --raw-success-base: var(--raw-color-green-500);
    --raw-success-light: var(--raw-color-green-200);
    --raw-success-lighter: var(--raw-color-green-50);

    --raw-verified-dark: var(--raw-color-sky-950);
    --raw-verified-base: var(--raw-color-sky-500);
    --raw-verified-light: var(--raw-color-sky-200);
    --raw-verified-lighter: var(--raw-color-sky-50);

    --raw-highlighted-dark: var(--raw-color-pink-950);
    --raw-highlighted-base: var(--raw-color-pink-500);
    --raw-highlighted-light: var(--raw-color-pink-200);
    --raw-highlighted-lighter: var(--raw-color-pink-50);

    --raw-stable-dark: var(--raw-color-teal-950);
    --raw-stable-base: var(--raw-color-teal-500);
    --raw-stable-light: var(--raw-color-teal-200);
    --raw-stable-lighter: var(--raw-color-teal-50);

    --raw-background: var(--raw-color-neutral-0);
    --raw-foreground: var(--raw-color-neutral-950);

    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 25 88% 83%;
  }

  @media (prefers-color-scheme: dark) {
    :root {
      /* Shadcn colors */
      --background: 0 0% 3.9%;
      --foreground: 0 0% 98%;
      --card: 0 0% 3.9%;
      --card-foreground: 0 0% 98%;
      --popover: 0 0% 3.9%;
      --popover-foreground: 0 0% 98%;
      --primary: 0 0% 98%;
      --primary-foreground: 0 0% 9%;
      --secondary: 0 0% 14.9%;
      --secondary-foreground: 0 0% 98%;
      --muted: 0 0% 14.9%;
      --muted-foreground: 0 0% 63.9%;
      --accent: 0 0% 14.9%;
      --accent-foreground: 0 0% 98%;
      --destructive: 0 62.8% 30.6%;
      --destructive-foreground: 0 0% 98%;
      --border: 0 0% 14.9%;
      --input: 0 0% 14.9%;
      --ring: 0 0% 83.1%;
      /* Shadcn colors end */

      /* Raw colors */
      --raw-static-black: var(--raw-color-neutral-950);
      --raw-static-white: var(--raw-color-neutral-0);
      --raw-strong-950: var(--raw-color-neutral-0);
      --raw-strong-400: var(--raw-color-neutral-600);
      --raw-surface-800: var(--raw-color-neutral-200);
      --raw-sub-600: var(--raw-color-neutral-400);
      --raw-sub-300: var(--raw-color-neutral-600);
      --raw-soft-400: var(--raw-color-neutral-500);
      --raw-soft-200: var(--raw-color-neutral-700);
      --raw-weak-50: var(--raw-color-neutral-900);
      --raw-weak-100: var(--raw-color-neutral-900);
      --raw-white-0: var(--raw-color-neutral-950);
      --raw-disabled-300: var(--raw-color-neutral-600);

      --raw-primary-dark: var(--raw-color-orange-800);
      --raw-primary-darker: var(--raw-color-orange-700);
      --raw-primary-base: var(--raw-color-orange-500);
      --raw-error-base: var(--raw-color-red-600);
      --raw-error-darker: var(--raw-color-red-700);
      --raw-error-light: var(--raw-color-red-alpha-24);
      --raw-error-lighter: var(--raw-color-red-alpha-16);

      --raw-success-dark: var(--raw-color-green-400);
      --raw-success-base: var(--raw-color-green-600);
      --raw-success-light: var(--raw-color-green-alpha-24);
      --raw-success-lighter: var(--raw-color-green-alpha-16);

      --raw-feature-dark: var(--raw-color-purple-400);
      --raw-feature-base: var(--raw-color-purple-500);
      --raw-feature-light: var(--raw-color-purple-alpha-24);
      --raw-feature-lighter: var(--raw-color-purple-alpha-16);

      --raw-faded-dark: var(--raw-color-neutral-300);
      --raw-faded-base: var(--raw-color-neutral-500);
      --raw-faded-light: var(--raw-color-neutral-alpha-24);
      --raw-faded-lighter: var(--raw-color-neutral-alpha-16);

      --raw-information-dark: var(--raw-color-blue-400);
      --raw-information-base: var(--raw-color-blue-500);
      --raw-information-light: var(--raw-color-blue-alpha-24);
      --raw-information-lighter: var(--raw-color-blue-alpha-16);

      --raw-away-dark: var(--raw-color-yellow-400);
      --raw-away-base: var(--raw-color-yellow-600);
      --raw-away-light: var(--raw-color-yellow-alpha-24);
      --raw-away-lighter: var(--raw-color-yellow-alpha-16);

      --raw-warning-dark: var(--raw-color-orange-400);
      --raw-warning-base: var(--raw-color-orange-600);
      --raw-warning-light: var(--raw-color-orange-alpha-24);
      --raw-warning-lighter: var(--raw-color-orange-alpha-16);

      --raw-verified-dark: var(--raw-color-sky-400);
      --raw-verified-base: var(--raw-color-sky-600);
      --raw-verified-light: var(--raw-color-sky-alpha-24);
      --raw-verified-lighter: var(--raw-color-sky-alpha-16);

      --raw-highlighted-dark: var(--raw-color-pink-400);
      --raw-highlighted-base: var(--raw-color-pink-600);
      --raw-highlighted-light: var(--raw-color-pink-alpha-24);
      --raw-highlighted-lighter: var(--raw-color-pink-alpha-16);

      --raw-stable-dark: var(--raw-color-teal-400);
      --raw-stable-base: var(--raw-color-teal-600);
      --raw-stable-light: var(--raw-color-teal-alpha-24);
      --raw-stable-lighter: var(--raw-color-teal-alpha-16);

      --raw-background: var(--raw-color-neutral-950);
      --raw-foreground: var(--raw-color-neutral-0);

      --chart-1: 220 70% 50%;
      --chart-2: 160 60% 45%;
      --chart-3: 30 80% 55%;
      --chart-4: 280 65% 60%;
      --chart-5: 12 40% 19%;

      color: rgb(var(--raw-strong-950));
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px rgb(var(--raw-white-0)) inset;
  -webkit-text-fill-color: rgb(var(--raw-strong-950));
  caret-color: rgb(var(--raw-strong-950));
}

input:-webkit-autofill:hover {
  -webkit-box-shadow: 0 0 0 1000px rgb(var(--raw-weak-50)) inset;
}

input:-webkit-autofill:hover:focus {
  -webkit-box-shadow: 0 0 0 1000px rgb(var(--raw-white-0)) inset;
}

textarea::-webkit-resizer {
  background-color: rgb(var(--raw-white-0));
  display: none;
}

textarea:hover::-webkit-resizer {
  background-color: rgb(var(--raw-weak-50));
}

textarea:focus:hover::-webkit-resizer {
  background-color: rgb(var(--raw-white-0));
}

/* hide up/down arrow key in number input */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
