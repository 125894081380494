body {
  font-family: 'Inter', sans-serif;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

p {
  color: #3d4043;
  height: 32px;
  display: flex;
  font-size: 14px;
  font-style: normal;
  align-items: center;
  font-weight: normal;
  line-height: 20px;
  font-stretch: normal;
}

.capitalize-first {
  text-transform: lowercase;
  &::first-letter {
    text-transform: uppercase;
  }
}

.font-inter {
  font-family: 'Inter', sans-serif !important;
}

.w-95-percentage {
  width: 95%;
}

.vertical-align-middle {
  vertical-align: middle;
}

.right-box-shadow {
  box-shadow: 120px 120px 200px rgba(0, 0, 0, 0.1);
}

.input-padding {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.button-padding {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.inverted-button-padding {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.display-2 {
  color: #3d4043;
  font-size: 32px;
  font-style: normal;
  font-weight: normal;
  line-height: 40px;
  font-stretch: expanded;
  letter-spacing: -0.01em;
}

.display-3 {
  color: #3d4043;
  font-size: 28px;
  font-style: normal;
  font-weight: normal;
  line-height: 40px;
  font-stretch: expanded;
  letter-spacing: -0.01em;
}

.display-4 {
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  font-stretch: expanded;
  color: #3d4043;
}

.heading {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  font-stretch: expanded;
  color: #202223;
}

.subheading {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  font-stretch: expanded;
  color: #202223;
}

.card-header {
  color: #3d4043;
  font-size: 22px;
  font-style: normal;
  font-weight: normal;
  line-height: 28px;
  font-stretch: expanded;
  letter-spacing: -0.01em;
}

.h-80vh {
  height: 80vh;
}

.mt-1\/10 {
  margin-top: 10%;
}

.mt-1\/5 {
  margin-top: 20%;
}

.mt-1\/20 {
  margin-top: 5%;
}

.onboardListItem {
  line-height: 24px;
  font-size: 16px;
}

.table-item-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}

.table-item-input-bg {
  background-color: #f1f5f8;
}

.transparent-bg {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.mt-4\.5 {
  margin-top: 1.1rem;
}

.max-h-65vh {
  max-height: 65vh;
}

.w-fill-right {
  width: 26.62281vw;
}

.line-height-16px {
  line-height: 16px;
}

.cards-grid {
  @apply columns-[349px] gap-x-6 space-y-6 [&>*]:break-inside-avoid;
  column-count: 3;
}
